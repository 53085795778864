import * as React from "react";
import styled, { keyframes, css } from "styled-components";
import Section from "../../../components/Section";
import { media, Typography, Box, Icon } from "@anyfin/ui";
import { useCountry } from "@anyfin/number-formatter/components";
import CopyContainer from "../../../components/CopyContainer";
import BottomStacks from "../components/BottomStacks";
import { useTranslation } from "react-i18next";
import { CustomerType } from "../../../types/types";

import letsGoSE from "../../../assets/offer/lets_go_se.png";
import letsGoDE from "../../../assets/offer/lets_go_de.png";
import letsGoFI from "../../../assets/offer/lets_go_fi.png";
import letsGoNO from "../../../assets/offer/lets_go_no.png";

const StyledSection = styled(Section)`
  background-color: ${({ theme }) => theme.colors.yellow.letters?.B};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  gap: 2rem;
  padding: 0.5rem;
`;

const bounce = keyframes`
  from { transform: translateY(-10px); }
  to { transform: translateY(10px); }
`;

const ReadMoreContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: ${bounce} 1s cubic-bezier(0.37, 0, 0.63, 1) infinite alternate;

  &:hover {
    cursor: pointer;
  }
`;

const ArrowContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: 0.25rem;
`;

const StyledIcon = styled(Icon)`
  height: 1.25em;
  width: 1.25em;

  &:not(:last-of-type) {
    margin-bottom: -0.95em;
  }
`;

const StyledBottomStacksSection = styled(Section)`
  padding: 0;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  max-height: 40%;
  width: 100%;
  padding-top: 24px;

  > img {
    max-width: 80%;
    height: auto;
  }
`;

const countryImg = () => ({
  SE: letsGoSE,
  FI: letsGoFI,
  DE: letsGoDE,
  NO: letsGoNO,
  se: letsGoSE,
  fi: letsGoFI,
  de: letsGoDE,
  no: letsGoNO,
  GB: letsGoSE,
  gb: letsGoSE
});

interface GreetingProps {
  customer: CustomerType;
  children: React.ReactNode;
  readMore: () => void;
  isTailoredOffer?: boolean;
  getProviderAvatars?: any;
}

const Greeting = ({
  children,
  readMore,
  isTailoredOffer = false,
  getProviderAvatars
}: GreetingProps) => {
  const [country] = useCountry();
  const { t } = useTranslation("offer");

  return (
    <>
      <StyledSection>
        <StyledBox align={"center"} marginBottom={2}>
          <ImageContainer>
            <img src={countryImg()[country]} alt="Offer Img" />
          </ImageContainer>
          {!isTailoredOffer && getProviderAvatars()}
          <CopyContainer>
            <Typography
              style={{
                fontSize: "1.4rem",
                whiteSpace: "break-spaces",
                textAlign: "center",
                paddingLeft: "0.8rem",
                paddingRight: "0.8rem"
              }}
            >
              {isTailoredOffer
                ? t("offer_language.new_header_subtitle_tailored_offer")
                : t("offer_language.new_header_subtitle")}
            </Typography>
          </CopyContainer>
          <Box>{children}</Box>
        </StyledBox>
      </StyledSection>
      <StyledBottomStacksSection>
        <BottomStacks totalStacks={3}></BottomStacks>
      </StyledBottomStacksSection>
      <ReadMoreContainer paddingTop={1.5} paddingBottom={1} onClick={readMore}>
        {t("read_more")}
        <ArrowContainer>
          <StyledIcon icon="ChevronDown"></StyledIcon>
          <StyledIcon icon="ChevronDown"></StyledIcon>
        </ArrowContainer>
      </ReadMoreContainer>
    </>
  );
};

export default Greeting;
